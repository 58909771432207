<script lang="ts">
	export let headerData
	import DeviceForm from './headers/DeviceForm.svelte'
	import GrantsForm from './headers/GrantsForm.svelte'
	import TextAndContact from './headers/TextAndContact.svelte'

	type ComponentList = {
		[key: string]: {
			this: any
			props: {
				[key: string]: string
			}
		}
	}

	const header = headerData[0]

	const COMPONENT_LIST: ComponentList = {
		ComponentPbHeadersTextAndContact: {
			this: TextAndContact,
			props: {
				title: 'title',
				subtext: 'subtext',
				contactTitle: 'contactTitle',
				phone: 'phone',
				email: 'email',
				background: 'background',
				backgroundMobile: 'backgroundMobile',
				mobileBgFitContact: 'mobileBgFitContact',
				bgAlign: 'bgAlignContact',
				buttons: 'buttons',
				paddingBottom: 'paddingBottom',
				paddingBottomMobile: 'paddingBottomMobile',
				showLogo: 'showLogo'
			}
		},
		ComponentPbHeadersDeviceForm: {
			this: DeviceForm,
			props: {
				title: 'title',
				showHVAC: 'showHVAC',
				showPV: 'showPV',
				showTabs: 'showTabs',
				background: 'background',
				backgroundMobile: 'backgroundMobile',
				mobileBgFitForm: 'mobileBgFitForm',
				bgAlign: 'bgAlignDevice',
				paddingBottom: 'paddingBottom',
				paddingBottomMobile: 'paddingBottomMobile',
				rating: 'rating'
			}
		},
		ComponentPbHeadersGrantsForm: {
			this: GrantsForm,
			props: {
				title: 'title',
				showHVAC: 'showHVAC',
				showPV: 'showPV',
				showTabs: 'showTabs',
				background: 'background',
				backgroundMobile: 'backgroundMobile',
				mobileBgFitForm: 'mobileBgFitGrantsForm',
				bgAlign: 'bgAlignGrants',
				paddingBottom: 'paddingBottom',
				paddingBottomMobile: 'paddingBottomMobile'
			}
		}
	}

	const getComponent = (component: Record<string, any | any[]>) => {
		if (!COMPONENT_LIST.hasOwnProperty(component?.__typename?.toString())) {
			logger.error(
				'strapiHeader',
				`Component mapping for typename ${component?.__typename} doesn't exist in COMPONENT_LIST`,
				component
			)
			return null
		}

		const props: Record<string, any | any[]> = {}
		Object.entries(COMPONENT_LIST[component.__typename].props).forEach(([key, value]) => {
			props[key] = value.split('.').reduce((p, c) => (p && p[c]) || null, component)
		})

		return {
			this: COMPONENT_LIST[component.__typename].this,
			props,
			component
		}
	}
</script>

{#if header}
	<svelte:component
		this={getComponent(header)?.this}
		{...getComponent(header)?.props}
		data={header}
	/>
{/if}
