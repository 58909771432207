<script lang="ts">
	import { page } from '$app/stores'
	import { ORGANIZATION } from '$lib/consts'
	import { t } from '$lib/translations'
	import { resizeCDNImage } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { getExpertContactModalState } from '$lib/stores'

	const expertContactModalState = getExpertContactModalState()

	type StrapiImage = {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let background: StrapiImage
	export let backgroundMobile: StrapiImage
	export let mobileBgFitContact: 'COVER' | 'CONTAIN' = 'COVER'
	export let title: string
	export let subtext: string
	export let contactTitle: string
	export let phone: {
		phoneNumber: string
		phoneLink: string
		availability: string
	}
	export let email: {
		emailAddress: string
		emailLink: string
		availability: string
	}
	export let bgAlign: 'TOP' | 'CENTER' | 'BOTTOM' = 'TOP'
	export let buttons: {
		text?: string
		link?: string
		onClickGTM?: string
		type: 'BLUE' | 'RED'
	}[]
	export let paddingBottom: string
	export let paddingBottomMobile: string
	export let showLogo: boolean

	const contactBoxVisible = contactTitle || phone || email

	const backgroundImage =
		resizeCDNImage(background?.data?.attributes?.url, 2000) ||
		'/images/backgrounds/woltair-homepage-background-de.webp'
	const backgroundImageMobile =
		backgroundMobile?.data?.attributes?.url ||
		'/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp'
</script>

<header class="relative w-full overflow-hidden">
	<div class="hidden sm:block">
		<div class="absolute -z-10 h-full w-full bg-black" />
		<div
			class="fix-safari-stutter absolute -z-10 h-full w-full bg-cover bg-center bg-no-repeat opacity-50 blur-3xl"
			style:background-image="url('{backgroundImage}')"
		/>
	</div>
	<div class="bg-image background relative z-20 mx-auto h-fit max-w-[2000px] overflow-clip">
		<div
			class="absolute z-10 hidden h-32 w-full bg-gradient-to-b from-white via-white/70 to-transparent sm:block"
		/>
		<picture
			class="absolute h-full w-full overflow-hidden {contactBoxVisible && 'pb-36'} sm:mt-0 sm:pb-0"
		>
			<source srcset={backgroundImage} media="(min-width: 640px)" />
			<img
				src={backgroundImageMobile}
				alt="Woltair Background"
				class="h-full w-full object-bottom sm:object-cover {mobileBgFitContact === 'CONTAIN'
					? 'object-contain'
					: 'object-cover'}"
				class:sm:object-top={bgAlign === 'TOP'}
				class:sm:object-center={bgAlign === 'CENTER'}
				class:sm:object-bottom={bgAlign === 'BOTTOM'}
			/>
		</picture>
		<div class="page-wrapper relative z-20 flex flex-col {contactBoxVisible && 'pb-16'} sm:pb-0">
			<div class="hidden h-12 w-full items-end justify-between text-xs sm:flex">
				<div class="flex flex-nowrap items-center gap-3">
					<a class="text-w-red-500" href="/">{ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].domain}</a>
					{#if $page.data?.title}
						<span class="h-fit"
							><img
								src="/images/icons/arrow/chevron-black.svg"
								alt="Arrow icon"
								class="h-[.675em]"
							/></span
						>
						<span>{$page.data.title}</span>
					{/if}
				</div>
				<div
					role="button"
					class="hover:underline"
					tabindex="0"
					on:keydown
					on:click={() => {
						expertContactModalState.update((state) => ({
							...state,
							open: true
						}))
					}}
				>
					{@html t('web.dynamic_pages.header.contactExpert.asButton', {
						class: 'text-w-red-500'
					})}
				</div>
			</div>
			<div
				class="mt-auto flex w-full flex-col items-start pr-2 sm:min-h-[450px] sm:gap-y-0 lg:flex-row lg:items-end {paddingBottomMobile ===
					null && 'gap-y-16'}"
			>
				<div class="relative text-white sm:my-12 lg:basis-1/2">
					{#if title}
						<h1 class="relative z-20 max-w-[640px] !text-5.5xl-res text-white">
							{@html title}
						</h1>
					{/if}
					{#if subtext}
						<p class="relative z-20 !mt-6 max-w-[640px] text-sm sm:max-w-[500px] sm:text-base">
							{@html subtext}
						</p>
					{/if}
					{#if buttons?.length > 0}
						<div class="mt-8 flex flex-wrap gap-3">
							{#each buttons as button}
								{#if button.link && button.text}
									<a
										href={button.link}
										class="btn-new relative z-20 {button?.type === 'BLUE'
											? 'btn-new-blue'
											: 'btn-new-red'}"
									>
										{@html button.text}</a
									>
								{/if}
							{/each}
						</div>
					{/if}
					<div
						class="fix-safari-stutter absolute left-0 top-0 z-0 h-full w-full bg-black text-right opacity-50 blur-3xl"
					/>
					{#if paddingBottom}
						<div class="hidden w-full bg-transparent sm:block" style="height: {paddingBottom}rem" />
					{/if}

					{#if paddingBottomMobile}
						<div class="w-full bg-transparent sm:hidden" style="height: {paddingBottomMobile}vw" />
					{/if}
				</div>
				{#if contactBoxVisible || showLogo}
					<div
						class="relative flex w-full items-end justify-end {contactBoxVisible
							? 'sm:self-end'
							: 'pb-36 sm:self-end'} lg:basis-1/2"
					>
						{#if showLogo}
							<img
								src="/images/icons/woltair-ff-logo.svg"
								alt="Woltair Logo"
								class="absolute -bottom-[30%] -z-10 h-auto w-[120px] {contactBoxVisible
									? 'sm:-bottom-[25%] sm:-right-[150px] sm:z-20 sm:w-[330px]'
									: 'bottom-2 sm:-bottom-[25%] sm:-right-[100px] sm:z-20 sm:w-[330px]'}"
							/>
						{/if}
						{#if contactBoxVisible && showLogo}
							<img
								src="/images/icons/woltair-ff-logo-right.svg"
								alt="Woltair Logo"
								class="absolute -bottom-[30%] z-10 h-auto w-[120px] sm:-bottom-[25%] sm:-right-[150px] sm:z-20 sm:w-[330px]"
							/>
						{/if}

						{#if contactBoxVisible}
							<div
								class="flex w-full max-w-[360px] flex-col gap-4 rounded-2xl bg-w-blue-950 p-8 text-white shadow-xl sm:m-16 sm:mr-20 sm:mt-0 sm:w-fit sm:max-w-none sm:bg-w-blue-950/40 sm:py-10 sm:pl-8 sm:backdrop-blur-xl"
							>
								{#if contactTitle}
									<h2 class="!mt-0 !text-base font-semibold text-white sm:!text-2xl">
										{contactTitle}
									</h2>
								{/if}

								{#if phone}
									<div class="flex items-center gap-4 sm:pr-32">
										<img src="/images/icons/phone-white.svg" alt="phone icon" class="h-6" />
										<a href={phone.phoneLink} class="flex flex-col"
											><span class="text-base font-semibold sm:text-2.5xl">{phone.phoneNumber}</span
											><span class="text-xs font-light opacity-60 sm:text-base"
												>({phone.availability})</span
											></a
										>
									</div>
								{/if}
								{#if email}
									<div class="flex items-center gap-4 sm:pr-32">
										<img src="/images/icons/email-white.svg" alt="email icon" class="h-6" />
										<a href={email.emailLink} class="flex flex-col"
											><span class="text-base font-semibold sm:text-2.5xl"
												>{email.emailAddress}</span
											><span class="text-xs font-light opacity-60 sm:text-base"
												>({email.availability})</span
											></a
										>
									</div>
								{/if}
							</div>
						{/if}
					</div>
				{/if}
			</div>
		</div>
	</div>
</header>

<style>
	.fix-safari-stutter {
		transform: translateZ(0);
	}
</style>
