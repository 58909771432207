<script lang="ts">
	import { page } from '$app/stores'
	import FormGrants from '$lib/components/WoltAdvisor/FormGrants.svelte'
	import { ORGANIZATION } from '$lib/consts'
	import { t } from '$lib/translations'
	import { resizeCDNImage } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { getExpertContactModalState } from '$lib/stores'

	const expertContactModalState = getExpertContactModalState()

	type StrapiImage = {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let background: StrapiImage
	export let backgroundMobile: StrapiImage
	export let navHeight = 80
	export let title: string
	export let showHVAC: boolean
	export let showPV: boolean
	export let showTabs: boolean
	export let mobileBgFitForm: 'COVER' | 'CONTAIN' = 'COVER'
	export let bgAlign: 'TOP' | 'CENTER' | 'BOTTOM' = 'TOP'
	export let paddingBottom: string
	export let paddingBottomMobile: string

	const backgroundImage =
		resizeCDNImage(background?.data?.attributes?.url, 2000) ||
		'/images/backgrounds/woltair-homepage-background-de.webp'
	const backgroundImageMobile =
		backgroundMobile?.data?.attributes?.url ||
		'/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp'
</script>

<header class="page-fullwidth h-fit overflow-hidden">
	<div class="hidden sm:block">
		<div class="absolute -z-10 h-full w-full bg-black" />
		<div
			class="fix-safari-stutter absolute -z-10 h-full w-full bg-cover bg-center bg-no-repeat opacity-50 blur-3xl"
			style:background-image="url('{backgroundImage}')"
		/>
	</div>

	<div
		class="bg-image background relative z-20 mx-auto h-fit max-w-[2000px] overflow-hidden !pt-0"
		style:padding-top="{navHeight}px"
	>
		<div
			class="absolute z-10 h-32 w-full bg-gradient-to-b from-white via-white/70 to-transparent"
		/>
		<picture class="absolute h-full w-full overflow-hidden sm:mt-0">
			<source srcset={backgroundImage} media="(min-width: 640px)" />
			<img
				src={backgroundImageMobile}
				alt="Woltair Background"
				class="h-full w-full sm:object-cover {mobileBgFitForm === 'CONTAIN'
					? 'object-contain object-bottom'
					: 'object-cover'}"
				class:sm:object-top={bgAlign === 'TOP'}
				class:sm:object-center={bgAlign === 'CENTER'}
				class:sm:object-bottom={bgAlign === 'BOTTOM'}
			/>
		</picture>

		<div
			class="sm:page-wrapper mx-2 flex flex-col justify-start sm:mx-auto sm:h-auto sm:max-h-none sm:min-h-0 sm:justify-start sm:pb-16"
		>
			<div class="z-20 hidden h-12 w-full items-end justify-between text-xs sm:flex">
				<div class="flex flex-nowrap items-center gap-3">
					<a class="text-w-red-500" href="/">{ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].domain}</a>
					{#if $page.data?.title}
						<span class="h-fit"
							><img
								src="/images/icons/arrow/chevron-black.svg"
								alt="Arrow icon"
								class="h-[.675em]"
							/></span
						>
						<span>{$page.data.title}</span>
					{/if}
				</div>
				<div
					role="button"
					class="hover:underline"
					tabindex="0"
					on:keydown
					on:click={() => {
						expertContactModalState.update((state) => ({
							...state,
							open: true
						}))
					}}
				>
					{@html t('web.dynamic_pages.header.contactExpert.asButton', {
						class: 'text-w-red-500'
					})}
				</div>
			</div>
			<div class="relative z-10 mt-8 h-fit max-h-full w-fit max-w-full rounded-2xl bg-w-blue-950">
				<FormGrants {title} {showHVAC} {showPV} showHiddenTabs={showTabs} />
			</div>
			{#if paddingBottom}
				<div class="hidden w-full bg-transparent sm:block" style="height: {paddingBottom}rem" />
			{/if}

			{#if paddingBottomMobile}
				<div class="w-full bg-transparent sm:hidden" style="height: {paddingBottomMobile}vw" />
			{/if}
		</div>
	</div>
</header>

<style>
	.fix-safari-stutter {
		transform: translateZ(0);
	}
</style>
