<script lang="ts">
	import { string, object } from 'yup'
	import { sendGTM } from '$lib/analytics'
	import { CONTACT_VARS } from '$lib/consts'
	import { getGrantsModalState } from '$lib/stores'
	import { t } from '$lib/translations'
	import ContactField from './contact-field/ContactField.svelte'
	import FormTabs from './tabs'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	const grantsModalState = getGrantsModalState()

	export let title: string | undefined = t('web.homepage.woltadvisorHeader')
	export let showHVAC = true
	export let showHiddenTabs = true
	export let showPV = false

	let selectedSegment = 0
	let type: 'hvac' | 'pv' = 'hvac'
	$: type = selectedSegment === 0 ? 'hvac' : 'pv'
	let sendingForm = false

	const selectChanged: { [key: string]: { [key: string]: boolean } } = {
		hvac: {
			houseHeatType: false,
			heatingPurpose: false
		},
		pv: {
			houseHeatType: false
		}
	}

	type HvacForm = {
		houseHeatType?: string | null
		heatingPurpose?: string | null
		zip?: string | null
	}

	type PvForm = {
		houseHeatType?: string | null
		annualConsumption?: string | null
		zip?: string | null
	}

	let hvacForm: HvacForm = {
		houseHeatType: 'null',
		heatingPurpose: 'null',
		zip: null
	}

	let pvForm: PvForm = {
		houseHeatType: 'null',
		annualConsumption: null,
		zip: null
	}

	let hvacErrors: Record<string, any> = {}
	let pvErrors: Record<string, any> = {}

	const schema = object({
		houseHeatType: string()
			.required(t('web.woltadvisor.houseHeatType.validation'))
			.min(1, t('web.woltadvisor.houseHeatType.validation')),
		heatingPurpose: string()
			.required(t('web.forms.heatingPurpose.validation.required'))
			.min(1, t('web.forms.heatingPurpose.validation.required')),
		zip: string()
			.required(t('web.forms.leaveContact.zip.required'))
			.min(3, t('web.forms.leaveContact.zip.validation'))
			.matches(/^[0-9]*$/, t('web.forms.leaveContact.zip.validation')),
		annualConsumption: string()
			.required(t('web.forms.annualyElectroConsumption.validation.required'))
			.matches(/^\d+$/, t('web.forms.annualyElectroConsumption.validation.format'))
	})

	async function validateField(type: string, fieldName: string, value: string) {
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			type === 'hvac' ? (hvacErrors[fieldName] = '') : (pvErrors[fieldName] = '')
		} catch (error: any) {
			type === 'hvac'
				? (hvacErrors[fieldName] = error.message)
				: (pvErrors[fieldName] = error.message)
		}
	}

	const filterDevices = (devices: any, withWater: boolean) => {
		return devices
			.map((device: any) => {
				if (withWater) {
					return device.withWater
				} else {
					return device.withWater
				}
			})
			.filter((device: any) => device?.name && device?.catalogPackage)
	}

	async function handleSubmitHVAC(event: Record<string, any>) {
		event.preventDefault()

		try {
			hvacErrors = {}
			await Promise.all(
				Object.entries(hvacForm).map(async ([key, value]) => {
					if (value === 'null') value = ''
					await validateField(type, key, value || '')
				})
			)

			if (Object.values(hvacErrors).some((error) => error)) throw new Error('Validation failed')

			sendingForm = true
			const params = {
				houseHeatType: hvacForm.houseHeatType,
				heatingPurpose: hvacForm.heatingPurpose,
				zip: hvacForm.zip || null
			}

			const data: any = await getWaPackage(type, params)

			const withWater = hvacForm.heatingPurpose === 'WITH_WATER'

			if (data?.woltAdvisorHvacFromQuery?.deviceTypes?.length > 0) {
				const filteredDevices = filterDevices(
					data?.woltAdvisorHvacFromQuery?.deviceTypes,
					withWater
				)

				filteredDevices.sort((a: any, b: any) => b.subsidy - a.subsidy)
				const highestSubsidyDevice = filteredDevices[0]
				sendGTM(
					'subsidy_calculator_submit_hvac',
					{},
					{
						calculator: {
							houseHeatType: hvacForm.houseHeatType,
							heatingPurpose: hvacForm.heatingPurpose,
							zip: hvacForm.zip || null
						},
						_clear: true
					}
				)
				grantsModalState.update((state) => ({
					...state,
					open: true,
					device: highestSubsidyDevice,
					params: {
						...params,
						formType: 'hvac'
					}
				}))
				sendingForm = false
			} else {
				grantsModalState.update((state) => ({
					...state,
					open: true,
					params: {
						...params,
						formType: 'hvac'
					}
				}))
			}
		} catch (error: any) {
			if (error.inner) {
				if (error.inner.length > 0)
					error.inner.forEach((err: any) => {
						hvacErrors[err.path] = err.message
					})
			}
		}
	}

	async function handleSubmitPV(event: Record<string, any>) {
		event.preventDefault()

		try {
			pvErrors = {}
			await Promise.all(
				Object.entries(pvForm).map(async ([key, value]) => {
					if (value === 'null') value = ''
					await validateField(type, key, value || '')
				})
			)

			if (Object.values(pvErrors).some((error) => error)) throw new Error('Validation failed')

			sendingForm = true
			const params = {
				houseHeatType: pvForm.houseHeatType,
				annualConsumption: pvForm.annualConsumption,
				zip: pvForm.zip || null
			}

			const data: any = await getWaPackage(type, params)

			if (data?.woltAdvisorFve?.variants?.length > 0) {
				const highestSubsidyDevice = data.woltAdvisorFve.variants.sort(
					(a: any, b: any) => b.grant - a.grant
				)[0]

				sendGTM(
					'subsidy_calculator_submit_fve',
					{},
					{
						calculator: {
							houseHeatType: pvForm.houseHeatType,
							annualConsumption: pvForm.annualConsumption,
							zip: pvForm.zip || null
						},
						_clear: true
					}
				)

				grantsModalState.update((state) => ({
					...state,
					open: true,
					device: highestSubsidyDevice,
					params: {
						...params,
						formType: 'pv'
					}
				}))
				sendingForm = false
			} else {
				grantsModalState.update((state) => ({
					...state,
					open: true,
					params: {
						...params,
						formType: 'pv'
					}
				}))
			}
		} catch (error: any) {
			if (error.inner) {
				if (error.inner.length > 0)
					error.inner.forEach((err: any) => {
						pvErrors[err.path] = err.message
					})
			}
		}
	}

	async function getWaPackage(type: string, params?: any) {
		const data = {
			formType: type,
			...(params && { ...params })
		}

		const response = await fetch('/api/request', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		})

		if (response.ok) {
			const data = await response.json()
			return data
		}
	}

	const contactVars = CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE] || CONTACT_VARS['WOLTAIR_CZ']
</script>

<div class="woltadvisor-calc h-fit bg-w-blue-950 px-0 py-10 sm:py-10">
	<div class="heading-container flex w-full justify-center px-6 sm:justify-start">
		<h2
			class="unstyled mt-0 text-center text-4xl-res font-semibold text-white sm:text-left sm:text-3.5xl"
		>
			{@html title}
		</h2>
	</div>
	<div class="relative w-full">
		<FormTabs.Tabs
			justify="center"
			bind:selectedTab={selectedSegment}
			showTabs={showHiddenTabs}
			paddingHorizontal="px-2 sm:px-6"
		>
			{#if showHVAC || showHiddenTabs}
				<FormTabs.TabItem
					testid={'header-form-hvac-tab'}
					title={t('web.forms.heatPump') || ''}
					icon="/images/icons/white/heat_pump.svg"
					iconDark="/images/icons/dark-blue/heat_pump.svg"
				>
					{#if showHVAC}
						<form on:submit={handleSubmitHVAC} id="heat-pump" class="px-2 sm:px-6" method="POST">
							<label
								for="heating-source"
								class="relative grid h-20 w-full items-center rounded-t-md border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2 sm:h-fit"
							>
								<span
									class="absolute top-2 z-10 col-start-1 row-start-1 mx-3 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70 sm:relative sm:top-0 sm:mx-4"
									>{t('web.woltadvisor.houseHeatType')}</span
								>
								<select
									id="heating-source"
									data-testid="select-house-heat-type"
									on:blur={() => {
										selectChanged.hvac.houseHeatType = true
										validateField(type, 'houseHeatType', hvacForm.houseHeatType || '')
									}}
									bind:value={hvacForm.houseHeatType}
									class="text-align-last-right col-start-1 row-start-1 mt-auto w-full cursor-pointer border-0 bg-transparent p-0 px-4 py-4 pr-8 text-right text-sm {selectChanged
										.hvac.houseHeatType
										? ' font-medium text-w-blue-950'
										: 'text-w-blue-950/40'} focus:ring-0"
								>
									<option disabled selected value="null">{t('web.forms.choose')}</option>
									{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ'}
										<option value="COAL" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL')}</option
										>
										<option value="COAL_I_II" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL_I_II')}</option
										>
										<option value="ELECTRO" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.ELECTRO')}</option
										>
										<option value="GAS" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS')}</option
										>
										<option value="GAS_OLD" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_OLD')}</option
										>
										<!-- {t('web.woltadvisor.houseHeatType.GAS')} -->
										<option value="GAS_CONDENSING" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_CONDENSING')}</option
										>
										<option value="HEATPUMP" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.HEATPUMP')}</option
										>
									{:else if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE'}
										<option value="ELECTRO" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.ELECTRO')}</option
										>
										<option value="GAS" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS')}</option
										>
										<option value="GAS_OLD" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_OLD')}</option
										>
										<!-- {t('web.woltadvisor.houseHeatType.GAS')} -->
										<option value="GAS_CONDENSING" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_CONDENSING')}</option
										>
										<option value="HEATPUMP" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.HEATPUMP')}</option
										>
										<option value="OIL" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.OIL')}</option
										>
									{:else}
										<option value="COAL" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL')}</option
										>
										<option value="COAL_I_II" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL_I_II')}</option
										>
										<option value="ELECTRO" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.ELECTRO')}</option
										>
										<option value="GAS" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS')}</option
										>
										<option value="GAS_CONDENSING" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_CONDENSING')}</option
										>
									{/if}
								</select>
								{#if hvacErrors.houseHeatType?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{hvacErrors.houseHeatType}</span
									>
								{/if}
							</label>

							<label
								for="heating-purpose"
								class="relative grid h-20 w-full items-center border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2 sm:h-fit"
							>
								<span
									class="absolute top-2 z-10 col-start-1 row-start-1 mx-3 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70 sm:relative sm:top-0 sm:mx-4"
									>{t('web.forms.heatingPurpose')}</span
								>
								<select
									id="heating-purpose"
									data-testid="select-heating-purpose"
									on:blur={() => {
										selectChanged.hvac.heatingPurpose = true
										validateField(type, 'heatingPurpose', hvacForm.heatingPurpose || '')
									}}
									bind:value={hvacForm.heatingPurpose}
									class="text-align-last-right col-start-1 row-start-1 mt-auto w-full cursor-pointer border-0 bg-transparent p-0 px-4 py-4 pr-8 text-right text-sm {selectChanged
										.hvac.heatingPurpose
										? ' font-medium text-w-blue-950'
										: 'text-w-blue-950/40'} focus:ring-0"
								>
									<option disabled selected value="null">{t('web.forms.choose')}</option>
									<option value="NO_WATER" class="text-neutral-900"
										>{t('web.forms.heatingPurpose.withWater')}</option
									>
									<option value="WITH_WATER" class="text-neutral-900"
										>{t('web.forms.heatingPurpose.noWater')}</option
									>
								</select>
								{#if hvacErrors.heatingPurpose?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{hvacErrors.heatingPurpose}</span
									>
								{/if}
							</label>

							<label
								for="zip"
								class="relative grid h-20 w-full items-center rounded-b-md border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2 sm:h-fit"
							>
								<span
									class="absolute top-2 z-10 col-start-1 row-start-1 mx-3 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70 sm:relative sm:top-0 sm:mx-4"
									>{t('web.forms.zipcode')}</span
								>
								<input
									id="zip"
									data-testid="input-zipcode"
									bind:value={hvacForm.zip}
									on:change={() => validateField(type, 'zip', hvacForm.zip || '')}
									placeholder={t('web.forms.zipcode.placeholder')}
									class="col-start-1 row-start-1 mt-auto w-full rounded-b-md border-0 bg-transparent p-0 px-4 py-4 text-right text-sm font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
								/>
								{#if hvacErrors.zip?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{hvacErrors.zip}</span
									>
								{/if}
							</label>
							<button
								type="submit"
								data-testid="header-form-hvac-submit"
								class="btn-new btn-new-red mt-4 w-full whitespace-normal rounded-md sm:mt-6 sm:rounded-lg"
								class:pointer-events-none={sendingForm}
								class:opacity-70={sendingForm}
							>
								{#if sendingForm}
									<img
										src="/images/icons/loader-2.svg"
										class="w-[1.25em] animate-spin"
										alt="Loader icon"
									/>{t('web.forms.checkGrant.inProgress')}
								{:else}
									{t('web.forms.checkGrant')}
								{/if}</button
							>
						</form>
					{:else if showHiddenTabs}
						<div class="w-full px-6 text-center">
							<p class="unstyled">{@html t('web.forms.grants.HVACnotAvailable.message')}</p>
							<p class="unstyled mb-6 pt-2 text-sm font-light opacity-70">
								{@html t('web.forms.grants.HVACnotAvailable.subtext')}
							</p>
							<ContactField type="HVAC" />
						</div>
					{/if}
				</FormTabs.TabItem>
			{/if}
			{#if showPV || showHiddenTabs}
				<FormTabs.TabItem
					testid={'header-form-pv-tab'}
					title={t('web.forms.photovoltaics') || ''}
					icon="/images/icons/white/flash.svg"
					iconDark="/images/icons/dark-blue/flash.svg"
				>
					{#if showPV}
						<form on:submit={handleSubmitPV} id="photovoltaics" class="px-2 sm:px-6" method="POST">
							<label
								for="heating-source"
								class="relative grid h-20 w-full items-center rounded-t-md border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2 sm:h-fit"
							>
								<span
									class="absolute top-2 z-10 col-start-1 row-start-1 mx-3 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70 sm:relative sm:top-0 sm:mx-4"
									>{t('web.woltadvisor.houseHeatType')}</span
								>
								<select
									id="heating-source"
									data-testid="select-houseHeatType"
									bind:value={pvForm.houseHeatType}
									on:blur={() => {
										selectChanged.pv.houseHeatType = true
										validateField(type, 'houseHeatType', pvForm.houseHeatType || '')
									}}
									class="text-align-last-right col-start-1 row-start-1 mt-auto w-full cursor-pointer border-0 bg-transparent p-0 px-4 py-4 pr-10 text-right text-sm font-medium {selectChanged
										.pv.houseHeatType
										? 'font-medium text-w-blue-950'
										: 'text-w-blue-950/40'} focus:ring-0"
								>
									<option disabled selected value="null">{t('web.forms.choose')}</option>
									{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ'}
										<option value="COAL" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL')}</option
										>
										<option value="COAL_I_II" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL_I_II')}</option
										>
										<option value="ELECTRO" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.ELECTRO')}</option
										>
										<option value="GAS" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS')}</option
										>
										<option value="GAS_OLD" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_OLD')}</option
										>
										<option value="GAS_CONDENSING" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_CONDENSING')}</option
										>
										<option value="HEATPUMP" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.HEATPUMP')}</option
										>
									{:else}
										<option value="COAL" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL')}</option
										>
										<option value="COAL_I_II" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.COAL_I_II')}</option
										>
										<option value="ELECTRO" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.ELECTRO')}</option
										>
										<option value="GAS" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS')}</option
										>
										<option value="GAS_CONDENSING" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.GAS_CONDENSING')}</option
										>
										<option value="HEATPUMP" class="text-neutral-900"
											>{t('web.woltadvisor.houseHeatType.HEATPUMP')}</option
										>
									{/if}
								</select>
								{#if pvErrors.houseHeatType?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{pvErrors.houseHeatType}</span
									>
								{/if}
							</label>

							<label
								for="annualConsumption"
								class="grid w-full items-center overflow-hidden border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2"
							>
								<span
									class="z-10 col-start-1 row-start-1 mx-4 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70"
									>{t('web.forms.annualConsumption.withMwh')}</span
								>
								<input
									id="annualConsumption"
									data-testid="input-annual-consumption"
									bind:value={pvForm.annualConsumption}
									placeholder="7"
									class="col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right text-sm font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
									on:change={() => {
										selectChanged.pv.annualConsumption = true
										validateField(type, 'annualConsumption', pvForm.annualConsumption || '')
									}}
								/>
								{#if pvErrors.annualConsumption?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{pvErrors.annualConsumption}</span
									>
								{/if}
							</label>

							<label
								for="zip"
								class="grid w-full items-center rounded-b-md border-b border-neutral-200 bg-white ring-inset ring-w-red-500/50 focus-within:ring-2 sm:rounded-b-lg"
							>
								<span
									class="z-10 col-start-1 row-start-1 mx-4 flex h-[2em] w-fit items-center whitespace-nowrap bg-white text-sm text-w-blue-950/70"
									>{t('web.forms.zipcode')}</span
								>
								<input
									id="zip"
									data-testid="input-zipcode"
									bind:value={pvForm.zip}
									placeholder={t('web.forms.zipcode.placeholder')}
									class="col-start-1 row-start-1 mt-auto w-full border-0 bg-transparent p-0 px-4 py-4 text-right text-sm font-medium text-w-blue-950 placeholder:font-normal placeholder:text-w-blue-950/40 focus:ring-0"
									on:change={() => validateField(type, 'zip', pvForm.zip || '')}
								/>
								{#if pvErrors.zip?.length > 0}
									<span class="-mt-4 py-1 pl-4 text-left text-sm text-w-red-500"
										>{pvErrors.zip}</span
									>
								{/if}
							</label>

							<button
								type="submit"
								data-testid="header-form-pv-submit"
								class="btn-new btn-new-red mt-4 w-full whitespace-normal rounded-md sm:mt-6 sm:rounded-lg"
								class:pointer-events-none={sendingForm}
								class:opacity-70={sendingForm}
							>
								{#if sendingForm}
									<img
										src="/images/icons/loader-2.svg"
										class="w-[1.25em] animate-spin"
										alt="Loader icon"
									/>{t('web.forms.checkGrant.inProgress')}
								{:else}
									{t('web.forms.checkGrant')}
								{/if}</button
							>
						</form>
					{:else if showHiddenTabs}
						<div class="w-full px-6 text-center">
							<p class="unstyled">{@html t('web.forms.grants.PVnotAvailable.message')}</p>
							<p class="unstyled mb-6 pt-2 text-sm font-light opacity-70">
								{@html t('web.forms.grants.PVnotAvailable.subtext')}
							</p>
							<ContactField type="PV" />
						</div>
					{/if}
				</FormTabs.TabItem>
			{/if}
		</FormTabs.Tabs>
	</div>
	<p class="unstyled w-full px-6 text-center text-sm">
		{@html t('web.forms.callUs', {
			phone: `<a class="whitespace-nowrap text-w-red-500" href="${contactVars.phoneRef}">${contactVars.phoneNum}</a>`,
			email: `<a href="mailto:${contactVars.mail}" class="text-w-red-500">${contactVars.mail}</a>`
		})}
	</p>
</div>

<style lang="scss">
	.text-align-last-right {
		text-align-last: right;
	}
	.woltadvisor-calc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 30px;
		border-radius: 16px;
		overflow: hidden;
		color: #fff;
		width: 100%;
		max-width: 470px;

		@screen sm {
			width: 550px;
		}
	}
</style>
